import React from "react"
import { graphql, Link } from "gatsby"
import * as PropTypes from "prop-types"
import Img from "gatsby-image"
import Helmet from "react-helmet"

import { Breadcrum, SEO, PostHeader, SocialLinks } from "../../components/index"
import * as postStyles from '../../components/posts/posts.module.scss'
import { allPostsType } from '../../types'
import { getDefaultPostData } from '../../utils/structuredData'

class FrameDesignPage extends React.PureComponent {
  constructor(props) {
    super(props);
    const { title, route } = props.data.allPostsJson.edges[0].node;
    this.breadcrumPages = [
      {
        text: 'Posts',
        to: '/posts/'
      },
      {
        text: title,
        active: true,
        to: route
      }
    ];
  }

  render () {
    const { allPostsJson } = this.props.data;
    const { 
      title, author, date, formattedDate, shortDescription, 
      imgSrc, theme, route, contentImages,
      socialImgSrc: { childImageSharp: { fluid: { src: socialSrc } } }
    } = allPostsJson.edges[0].node;
    return (
      <>
        <SEO title={title} description={shortDescription} pageUrl={route} imgSrc={socialSrc} />
        <Helmet>
          <script type="application/ld+json">{getDefaultPostData({
            route: route, title: title, 
            shortDescription: shortDescription, date: date,
            keywords: ['frame', 'design', 'M20', 'core set 2020', 'art', 'card art'],
            img: socialSrc,
            modifiedDate: "2019-06-23"
          })}
          </script>
        </Helmet>
        <Breadcrum pages={this.breadcrumPages} />
        <article className={postStyles.article}>
          <PostHeader
            imgSrc={imgSrc}
            title={title}
            author={author}
            date={formattedDate}
            theme={theme}
          /> 
          <SocialLinks title={title} />
          <div className={postStyles.body}>
            <p>Inspired by the new Magic: the Gathering token card frame design seen in the Core Set 2020 spoilers, lets take a look back through the history and evolution of the MTG token frame. We'll cover all the major and minor design changes over the last 22 years including:</p>
            <ul>
              <li><strong>2019 - Core Set 2020 (re-design)</strong></li>
              <li>2018 - Dominaria (legendary name design)</li>
              <li>2017 - Unstable (full art design)</li>
              <li><strong>2014 - Magic 2015 Core Set (re-design)</strong></li>
              <li>2013 - Theros (enchantment background)</li>
              <li>2010 - Rise of the Eldrazi (colorless background)</li>
              <li>2008 - Shards of Alara (gold background)</li>
              <li>2008 - Shadowmoor (two color backgrounds)</li>
              <li><strong>2003 - Scourge (re-design)</strong></li>
              <li><strong>2000 - Invasion (re-design)</strong></li>
              <li><strong>1998 - Unglued (first design!)</strong></li>
            </ul>
            <h2>Major frame design changes</h2>
            <Img fluid={contentImages[0].childImageSharp.fluid} alt="MTG token card frame design 1998-2019" className={postStyles.contentImg} />
            <p className={postStyles.caption}>A 1/1 White Soldier across the five major re-designs of MTG token cards.</p>
            {/* <p>Starting with the new M20 frame, spoilt just this week with the release of Core set 2020. M15 frame we've seen for the last 5 years. The modern frame first seen in Scourge 2003. The player reward's frame introduced in Invasion 2000 and finally the Unglued frame, the first MTG token designed in 1998.</p> */}
            <h3>2019 - M20 frame</h3>
            <p>The recent Core set 2020 spoilers revealed a brand new, full art, token design! To make room for the new, larger artwork, the colored background has been removed. To identify the token color, the background of the token name is now the card's colour and the text has been changed from gold to either black or white. The text box background and border is also a slightly darker shade of the tokens color.</p>
            <Img fluid={contentImages[1].childImageSharp.fluid} alt="MTG token - new card frame design in Core Set 2020" className={postStyles.contentImg} />
            <p className={postStyles.caption}>Comparing the design of M20 and M15 tokens with a full art 1/1 Red Elemental and 1/1 White Spirit (with rules text).</p>
            <h3>2014 - M15 Frame</h3>
            <p>In 2014 Wizards of the Coast introduced a new card frame across MTG cards and tokens alike. The new design featured the Magic font Beleren, a reduced background size and shape, revamped collector info, a full width token name and a smaller border size. One of the main reasons for the change was the facilitation of digitized printing, so that the machine could read the card number.</p>
            <p>In another notable change, the card type "Token" was prefixed to the token type line. In the collector info, language and set was added. The token number became clearer and was presented in a consistent format (I.e. 001/020 T).</p>
            <Img fluid={contentImages[2].childImageSharp.fluid} alt="MTG token M15 frame design" className={postStyles.contentImg} />
            <p className={postStyles.caption}>Comparing the design of M15 and Modern Frame tokens with a full art 1/1 Red Elemental and 1/1 White Spirit (with rules text).</p>
            <h3>2003 - Modern Frame</h3>
            <p>The modern token frame was introduced in 2003 with Scourge and 8th Edition, at a time where tokens were just starting to become a part of each set release. Until this point they had only been given away as player and judge rewards at various Magic events.</p>
            <p>The design changed drastically, becoming more consistent with MTG cards in appearance and layout. The token name, types, expansion symbol, power/toughness were all given their own boxes to elevate them from the card frame and enhance readability. Coloured token backgrounds were introduced. The font was updated to Matrix Bold, from Goudy Medieval. The "TOKEN" text and flavor text were both removed. If the token had no abilities, the text box is also completely removed, allowing for a larger illustration. Finally, a collectors number was added to the end of the copyright message.</p>
            <Img fluid={contentImages[3].childImageSharp.fluid} alt="MTG modern token frame compared to 2000 player reward frame" className={postStyles.contentImg} />
            <p className={postStyles.caption}>Comparing the design of the Modern Frame and Player Reward Frames with a full art 1/1 Red Goblin and 1/1 White Spirit (with rules text).</p>
            <h3>2000 - Players Reward Frame</h3>
            <p>The Invasion block, Odyssey block and Onslaught block all featured player reward tokens - the first token designs to share many similarities with MTG token cards. They are visually very different from the full art Unglued tokens as they contain rules text, creature type and subtype, flavour text, power/toughness and an expansion symbol.</p>
            <Img fluid={contentImages[4].childImageSharp.fluid} alt="MTG Player Reward Frame compared to Unglued full art tokens" className={postStyles.contentImg} />
            <p className={postStyles.caption}>Comparing the design of the Player Reward Frames and Unglued tokens with a full art 1/1 Red Goblin and 1/1 White Soldier (with flavour text).</p>
            <h3>1998 - Unglued Frame</h3>
            <p>Unglued was the first Magic set to officially print token cards. The 1/1 Red Goblin and 1/1 White Pegasus were later re-printed in Anthologies.</p>
            <p>Full art tokens like these didn't make a return to Magic until Unstable, another un-set released in 2017.</p>
            <br />
            <h2>Minor frame design changes</h2>
            <h3>2018 - Legendary name design change</h3>
            <p>Dominaria emphasized the design of the token name box for legendary tokens types.</p>
            <Img fluid={contentImages[5].childImageSharp.fluid} alt="MTG Legendary name token design change" className={postStyles.contentImg} />
            <p className={postStyles.caption}>Legendary name design change seen on Tuktuk the Returned and Marit Lage.</p>
            <h3>2017 - Unstable full art tokens</h3>
            <p>As a throw back to Unglued, un-set Unstable featured full art token cards. They're beautiful but sadly didn't become a regular thing.</p>
            <p>
              <Link to="/tokens/ust/">View the Unstable tokens here</Link>
            </p>
            <h3>2013 - Enchantment background design</h3>
            <p>Theros block added a new background style for Enchantment token cards.</p>
            <Img fluid={contentImages[7].childImageSharp.fluid} alt="MTG Enchantment background token design" className={postStyles.contentImg} />
            <p className={postStyles.caption}>Enchantment background design on a 3/3 Colorless Golem and 1/1 White Soldier.</p>
            <h3>2010 - Colorless background</h3>
            <p>Rise of the Eldrazi block included a new colorless background design.</p>
            <Img fluid={contentImages[6].childImageSharp.fluid} alt="MTG Colorless background token design" className={postStyles.contentImg} />
            <p className={postStyles.caption}>Colorless background design on a 0/1 Eldazi compared to a 1/1 Shapeshifter and 1/1 Spirit.</p>
            <h3>2008 - Gold background and two colour backgrounds</h3>
            <p>Shards of Alara contained the first Gold creature card backgrounds. Up until this set, only one and two coloured token cards existed.</p>
            <p>Apocalypse contained the very first two colored token, but it wasn't until Shadowmoor in 2008 that two color tokens got their own multi-colored background design.</p>
            <Img fluid={contentImages[8].childImageSharp.fluid} alt="MTG Gold background and two colour background token design" className={postStyles.contentImg} />
            <p className={postStyles.caption}>The first Gold background color token 8/8 Beast and comparing the design of a 1/1 Red White Goblin Soldier.</p>
            <br />
            <p>Thanks for reading - Adam</p>
          </div>
        </article>
      </>
    )
  }
}

FrameDesignPage.propTypes = {
  data: PropTypes.shape({
    allPostsJson: allPostsType.isRequired,
  }).isRequired
}

export const frameDesignPageQuery = graphql`
  query FrameDesignPageQuery {
      allPostsJson(filter: {
        id: {
          eq: "post4"
        }
      }) {
        edges {
          node {
            id
            title
            author
            route
            shortDescription
            imgSrc {
              childImageSharp {
                fluid(maxWidth: 270) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
            socialImgSrc {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            theme
            date
            formattedDate: date(formatString: "MMMM Do, YYYY")
            contentImages {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                  base64
                }
              }
            }
          }
        }
      }
    }
  `

export default FrameDesignPage
